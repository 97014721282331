@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}
body{
  font-family: 'Montserrat', sans-serif;
  background: radial-gradient(circle, #EEAECA 0%, #94BBE9 100%);
  box-sizing:border-box;
}

